table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;

  .table-controls {
    justify-content: flex-end;
    display: flex;
    gap: 8px;
  }

  tbody {
    tr {
      &:not(:last-of-type) {
        border-bottom: 2px solid $lightGrey;
      }
    }
  }

  thead {
    border-bottom: 2px solid $lightGrey;
  }

  tr {
    &.tr-green {
      background-color: lighten($green, 15%);
    }

    &.tr-red {
      background-color: lighten($red, 15%);
    }

    td,
    th {
      padding: 8px;

      &.td-minimum {
        width: 1px;
      }

      &.td-no-padding {
        padding: 0px;
      }
    }

    th {
      padding-bottom: 8px;
    }
  }
}

.attributes-table {
  max-height: 300px;
  overflow-y: auto;
}
