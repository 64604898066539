.audit-log-list {
  max-width: 350px;

  ul {
    list-style: none;
    padding: 0;
    margin: 8px 0;

    li {
      padding: 8px;
      display: flex;
      align-items: center;
      gap: 16px;
      font-size: 15px;

      .audit-icon {
        font-size: 24px;
        display: inline-flex;
      }
    }
  }
}

.audit-log-full-wrapper {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  overflow: auto;

  .audit-log-full {
    margin: 0 auto;
    max-width: 1200px;
  }

  .audit-log-header-wrapper {
    background-color: $lightGrey;
    padding: 8px;

    .audit-log-header {
      display: flex;
      align-items: center;
      max-width: 1200px;
      margin: auto;

      h2 {
        flex: 1;
        margin: 0;
      }
    }
  }

  .audit-log-content {
    display: flex;
    margin-top: 32px;
    gap: 16px;

    .audit-log-list {
      border: 2px solid $lightGrey;
      padding: 16px;
      border-radius: 4px;

      ul {
        margin-top: 16px;

        li {
          &:hover {
            cursor: pointer;
            background-color: $lightGrey;
          }

          &.selected {
            background-color: $lightGrey;
            border-radius: 4px;
          }
        }
      }
    }

    .audit-log-diff {
      overflow-y: auto;
      display: flex;
      border: 2px solid $lightGrey;
      padding: 16px;
      border-radius: 4px;
      flex: 1;

      .code-block {
        flex: 1;

        strong {
          padding: 0 16px;
        }
      }

      .code,
      .code span {
        font-family: 'Ubuntu Mono';
        display: block;
        position: relative;
        padding: 2px 0px;

        span {
          line-height: 20px;
          min-height: 20px;
          padding: 0 20px;
        }

        .changelog-icon {
          height: 20px;
          position: absolute;
          left: 10px;
        }

        svg.added {
          color: $green;
        }

        svg.modified {
          color: $yellow;
        }

        svg.deleted {
          color: $red;
        }

        span.added {
          background-color: rgba($green, 0.3);
        }

        span.modified {
          background-color: rgba($yellow, 0.3);
        }

        span.deleted {
          background-color: rgba($red, 0.3);
        }
      }
    }
  }
}
