.user-bar-wrapper {
  background-color: $darkGrey;
  color: $white;
  padding: 16px;

  .user-bar-content {
    max-width: $contentWidth;
    margin: auto;
  }
}

.user-bar-border {
  background: $primary;
  height: 3px;
}
