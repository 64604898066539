.email-list {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-content: center;

  li {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  &__text {
    flex: 1;
  }
}
