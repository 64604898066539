.navigation-wrapper {
  background-color: $lightGrey;
  padding: 16px;

  .search-bar {
    width: 200px;
  }

  .navigation-content {
    max-width: $contentWidth;
    margin: auto;
    display: flex;
    align-items: center;
    gap: 12px 48px;

    .navigation-links {
      list-style: none;
      padding: 0;
      display: flex;
      gap: 48px;
      flex: 1;

      li {
        color: $darkGrey;

        &.active {
          font-weight: bold;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.subnavigation-wrapper {
  background-color: $midGrey;
  padding: 0px 15px;

  .subnavigation-content {
    max-width: $contentWidth;
    margin: auto;
    display: flex;
    align-items: center;
    gap: 48px;

    .subnavigation-links {
      list-style: none;
      padding: 0;
      display: flex;
      gap: 32px;
      flex: 1;

      li {
        color: $darkGrey;

        &.active {
          font-weight: bold;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
