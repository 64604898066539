.banner {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-radius: 4px;

  h4,
  p {
    margin: 5px;
  }

  .banner-text {
    flex: 1;
  }

  &.banner-green {
    background-color: $green;
    color: $white;
  }

  &.banner-red {
    background-color: $red;
    color: $white;
  }

  &.banner-yellow {
    background-color: $yellow;
  }

  &.banner-blue {
    background-color: $blue;
    color: $white;
  }
}
