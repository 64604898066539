.text-green {
  color: $green;
}

.text-red {
  color: $red;
}

.text-yellow {
  color: $yellow;
}

.text-super-dark-grey {
  color: $superDarkGrey;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-col {
  flex-direction: column;
}

.gap-1 {
  gap: 8px;
}

.gap-2 {
  gap: 16px;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.center {
  align-items: center;
  justify-content: center;
}

.gap-1 {
  gap: 8px;
}

.gap-2 {
  gap: 16px;
}

.gap-3 {
  gap: 24px;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mr-1 {
  margin-right: 8px;
}

.mb-1 {
  margin-bottom: 8px;
}

.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: 8px;
}

.mr-2 {
  margin-right: 16px;
}

.mb-2 {
  margin-bottom: 16px;
}

.ml-2 {
  margin-left: 16px;
}

.ml-3 {
  margin-left: 24px;
}

.my-1 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-2 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mx-1 {
  margin-left: 8px;
  margin-right: 8px;
}

.mx-2 {
  margin-left: 16px;
  margin-right: 16px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: 8px;
  padding-right: 8px;
}

.w-100 {
  width: 100%;
}

.text-muted {
  color: $superDarkGrey;
}

.text-green {
  color: $green;
}

.text-red {
  color: $red;
}

.text-primary {
  color: $primarySolid;
}

.hover {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-sm {
  font-size: 14px;
}

.separator {
  width: 100%;
  border-bottom: 2px solid $lightGrey;
}
