.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    max-width: 100%;
    max-height: 60%;
    overflow-y: auto;

    &.modal-sm {
      width: 400px;
    }

    &.modal-lg {
      width: 600px;
    }
    &.modal-xl {
      width: 1000px;
    }

    .modal-header {
      h2 {
        font-size: 24px;
        margin: 0;
        font-weight: 500;
      }
      margin-bottom: 16px;
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      gap: 8px;
      margin-top: 16px;

      &.right {
        justify-content: flex-end;
      }
    }
  }
}
