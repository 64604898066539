.alert-container {
  position: fixed;
  max-width: $contentWidth;
  margin: 0 auto;
  z-index: 1000;
  bottom: 16px;
  right: 16px;

  .alert {
    padding: 16px;
    border-radius: 3px;
    color: #000;
    margin: 16px 0;
    width: 250px;

    &.alert-attention {
      background-color: $red;
      color: #fff;
    }

    &.alert-notice {
      background-color: $blue;
      color: $white;
    }

    &.alert-success {
      background-color: $green;
      color: $white;
    }

    &.alert-update {
      background-color: $white;
    }

    .alert-header {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .alert-title {
        flex: 1;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 5px;
        margin: 0;
      }

      .alert-close {
        font-size: 20px;
        cursor: pointer;
        line-height: 10px;
      }
    }

    .alert-body {
      font-size: 12px;
      margin: 0;
    }
  }
}
