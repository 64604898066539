.box {
  background-color: $lightGrey;
  padding: 16px;
  border-radius: 4px;

  .box-header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .box-title {
      flex: 1;
      margin: 0;
    }

    .close {
      font-size: 24px;
      line-height: 16px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
