a {
  color: $red;
  background: $primary;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
}

a.link-white {
  color: $white;
  font-weight: bold;
  background: none;
  -webkit-text-fill-color: $white;

  &:hover {
    color: darken($white, 20%);
    -webkit-text-fill-color: darken($white, 20%);
  }
}
