input[type='text'],
input[type='password'],
input[type='number'],
input[type='email'],
select {
  padding: 0px 16px;
  border: 2px solid $superDarkGrey;
  color: $darkGrey;
  border-radius: 4px;
  min-width: 200px;
  display: block;
  height: 34px;
  box-sizing: border-box;

  &::placeholder {
    color: $superDarkGrey;
  }

  &:focus-visible,
  &:focus {
    border: 2px solid $primarySolid;
    outline: none;
  }
}

textarea {
  display: block;
  padding: 8px 16px;
  border: 2px solid $superDarkGrey;
  color: $darkGrey;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;

  &::placeholder {
    color: $superDarkGrey;
  }

  &:focus-visible,
  &:focus {
    border: 2px solid $primarySolid;
    outline: none;
  }
}

.input-group-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input-group {
  display: block;
  position: relative;
  width: 100%;

  label {
    display: inline-block;
    margin-bottom: 8px;

    .required {
      color: $primarySolid;
    }
  }

  input[type='text'],
  input[type='password'],
  input[type='number'],
  input[type='email'],
  select {
    width: 100%;
    box-sizing: border-box;
  }

  .input-group-error {
    color: $red;
    font-size: 12px;
  }
}

.caption-group {
  position: relative;

  .caption {
    position: absolute;
    font-size: 12px;
    top: -15px;
  }
}
