.dropdown-wrapper {
  position: relative;

  .dropdown-label {
    display: block;
    margin-bottom: 8px;
  }

  .dropdown-trigger {
    padding-left: 16px;
    border: 2px solid $superDarkGrey;
    border-radius: 4px;
    color: $darkGrey;
    min-width: 200px;
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 13.3333px;

    .dropdown-placeholder {
      flex: 1;
    }

    .dropdown-icon {
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.dropdown {
  position: absolute;
  background-color: $white;
  -webkit-box-shadow: 0px 20px 50px -10px #000000;
  box-shadow: 0px 20px 50px -10px #000000;
  border-radius: 4px;
  z-index: 1000;

  .dropdown-search {
    margin: 8px;

    input {
      width: 100%;
      box-sizing: border-box;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 8px 16px;
      font-size: 13.333px;

      &:hover {
        background-color: $primarySolid;
        color: $white;
        cursor: pointer;
      }

      &.active {
        background-color: $primarySolid;
        color: $white;
      }
    }
  }
}
