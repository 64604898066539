.auth-page-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .auth-page {
    border: 3px solid $midGrey;
    border-radius: 8px;
    padding: 64px 16px;
    min-width: 400px;
    text-align: center;

    .content {
      button {
        margin: auto;
      }
    }
  }
}
