.badge {
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-block;

  &.badge-grey {
    background-color: $lightGrey;
  }

  &.badge-green {
    background-color: $green;
    color: $white;
  }

  &.badge-red {
    background-color: $red;
    color: $white;
  }

  &.badge-yellow {
    background-color: $yellow;
  }

  &.badge-blue {
    background-color: $blue;
    color: $white;
  }

  &.badge-omni {
    background: $primary;
    color: $white;
  }

  &.badge-sm {
    font-size: 14px;
  }
}
