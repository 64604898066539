.notification-dropdown-wrapper {
  position: relative;
  height: 16px;

  .notification-icon {
    cursor: pointer;

    &.active {
      color: $primary;
    }
  }

  .notification-dropdown {
    position: absolute;
    top: 32px;
    right: 0;
    width: 450px;
    z-index: 1000;
    background-color: $white;
    border-radius: 4px;
    border: 2px solid $lightGrey;

    .notification-inner-dropdown {
      width: 100%;
      text-align: left;

      > .header {
        display: flex;
        padding: 15px;

        p {
          color: $darkGrey;
        }

        p:not(:last-of-type) {
          margin-bottom: 5px;
        }
      }

      .horizontal-separator {
        background-color: $lightGrey;
      }

      .header {
        display: inline-flex;
        justify-content: space-between;
        color: $darkGrey;

        .right {
          color: $superDarkGrey;
          font-size: 12px;
        }
      }

      .notification {
        padding: 15px;
        display: grid;
        color: $darkGrey;

        a {
          color: $red;
          font-weight: 600;
          background: $primary;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          margin-left: 0;
          margin-right: 15px;
          margin-bottom: 5px;
        }

        .content {
          margin-top: 5px;
          color: $darkGrey;
        }
      }

      .notification {
        border-top: 2px solid $lightGrey;
      }

      .notifications-link {
        padding: 10px;
        text-align: center;
        border-top: 2px solid $lightGrey;

        a {
          color: $red;
          background: $primary;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.notification-page {
  padding: 10px;
  height: 100%;
  display: flex;
  gap: 16px;

  .notification-list {
    border: 2px solid $lightGrey;
    border-radius: 4px;
    width: 300px;

    .notification-list-item {
      padding: 10px;
      cursor: pointer;

      &:hover,
      &.active {
        background-color: $lightGrey;
      }

      .notification-list-item-header {
        .notification-list-item-title-0 {
          color: $red;
          font-weight: 600;
          background: $primary;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .notification-list-item-title-1 {
          font-weight: 600;
        }

        .notification-list-item-timestamp {
          font-size: 12px;
          float: right;
        }
      }
    }
  }

  .notification-view {
    border: 2px solid $lightGrey;
    border-radius: 4px;

    .notification-view-content {
      margin: 15px;

      .notification-view-header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .notification-view-title {
          margin: 0;
          flex: 1;
        }

        .notification-view-timestamp {
          color: $darkGrey;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .notification-view-markdown {
        h1 {
          font-size: 30px;
        }

        blockquote {
          border-left: 5px solid #e51e6f;
          margin-left: 0;
          padding-left: 15px;
          padding-top: 5px;
          padding-bottom: 5px;
        }

        a,
        a:visited {
          color: #e51e6f;
        }

        pre {
          background-color: $white;
          border: 2px solid $lightGrey;
          padding: 10px;
        }
      }
    }
  }
}
