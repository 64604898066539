.button {
  padding: 8px 16px;
  border-radius: 3px;
  border: none;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-clip: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: inherit;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }

  &.primary {
    background: $primary;
    color: $white;

    &:hover {
      background: $primaryDark;
      color: darken($white, 5%);
    }

    &.disabled {
      background: $primaryLight;
      color: darken($white, 10%);
      cursor: default;
    }
  }

  &.secondary {
    color: $darkGrey;
    background: $lightGrey;

    &:hover {
      background-color: darken($lightGrey, 5%);
    }

    &.disabled {
      background-color: lighten($lightGrey, 2%);
      color: lighten($darkGrey, 20%);
      cursor: default;
    }
  }

  &.full {
    width: 100%;
  }
}
