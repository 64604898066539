$primary: linear-gradient(to left, #fc3a4c 0%, #ce0390 100%);
$primarySolid: #e51e6f;
$primaryDark: linear-gradient(
  to left,
  darken(#fc3a4c, 5%) 0%,
  darken(#ce0390, 5%) 100%
);
$primaryLight: linear-gradient(
  to left,
  lighten(#fc3a4c, 20%) 0%,
  lighten(#ce0390, 20%) 100%
);

$darkGrey: #3c3a40;
$midGrey: #eaeaea;
$lightGrey: #efefef;
$superDarkGrey: #bfbfbf;

$white: #ffffff;

$green: #3dd16f;
$red: #fd4646;
$yellow: #fdd546;
$blue: #50b9da;

$font: 'Ubuntu', sans-serif;

$contentWidth: 1200px;
