.search-wrapper {
  position: relative;
  max-width: 400px;
  min-width: 115px;

  .search-bar {
    position: relative;
    display: flex;
    gap: 5px;
    align-items: center;
    border: 1px solid $superDarkGrey;
    color: $darkGrey;
    font-weight: normal;
    height: 29px;
    overflow: hidden;
    width: 100%;

    border-radius: 5px;
    border-width: 2px;

    -webkit-font-smoothing: antialiased;
    background-color: $white;

    .search-icon {
      padding: 0 5px;
      margin-top: 5px;
    }

    .search-scroll-wrapper {
      display: flex;
      flex: 1;
      height: 22px;
      overflow: hidden;
      align-items: flex-start;

      .search-scroll {
        overflow-x: scroll;
        display: flex;
        align-items: center;
        gap: 5px;
        flex: 1;
      }
    }

    .search-field {
      border: none;
      padding: 5px 0;
      flex: 1;
      min-width: 200px;
      height: auto;
    }

    .search-slash {
      background-color: $lightGrey;
      font-size: 10px;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      border-radius: 2px;
      color: $darkGrey;
      margin: 0px 5px;
    }
  }

  .search-dropdown {
    position: absolute;
    background: white;
    width: 100%;
    border: 2px solid $superDarkGrey;
    border-top: none;
    z-index: 1000;
    text-align: left;
    border-radius: 5px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      padding: 5px 0;
      line-height: 22px;

      li {
        cursor: pointer;
        padding: 5px 10px;
        font-size: 14px;

        &:hover {
          color: white;
          background-color: #1e90ff;
        }

        &.selected {
          color: white;
          background-color: #1e90ff;
        }
      }
    }

    p {
      padding: 5px;
      font-size: 14px;
    }

    .show-all-results {
      margin: 0 10px 5px 10px;

      a {
        font-size: 14px;
      }
    }
  }
}

.search-results {
  list-style: none;
  padding-left: 0;

  li {
    padding: 10px 5px;

    p {
      margin-bottom: 0;
    }
  }
}

.search-block {
  background-color: $lightGrey;
  font-size: 12px;
  padding: 3px 5px;
  border-radius: 5px;
  color: $darkGrey;
  white-space: nowrap;
  margin: 5px 0px;
}
