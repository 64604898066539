@import 'variables';
@import 'utilities';
@import 'user-bar';
@import 'navigation';
@import 'links';
@import 'input';
@import 'buttons';
@import 'alerts';
@import 'tables';
@import 'paginator';
@import 'modal';
@import 'auth';
@import 'box';
@import 'dropdown';
@import 'card';
@import 'badge';
@import 'banner';
@import 'audit';
@import 'search';
@import 'notifications';
@import 'email-list';

* {
  font-family: $font;
}

body {
  margin: 0;
  color: $darkGrey;
}

.page-wrapper {
  max-width: $contentWidth;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 48px;
  padding: 0 15px;
}

.page-form {
  max-width: 400px;
  margin: auto;
}

.sidepanel {
  min-width: 300px;
}

.loader {
  animation-name: loader;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
