.paginator {
  display: flex;
  justify-content: center;
  padding: 8px;
  align-items: center;

  .paginator-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    font-weight: bold;

    .icon {
      font-size: 24px;
    }

    &:hover {
      cursor: pointer;
      background-color: $midGrey;
    }

    &.active {
      background-color: $lightGrey;
    }

    &.disabled {
      color: darken($lightGrey, 20%);

      &:hover {
        background-color: $white;
        cursor: default;
      }
    }
  }
}
